// Full Calendar Plugins
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import listPlugin from '@fullcalendar/list'
import interactionPlugin from '@fullcalendar/interaction'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import useNotifications from '@/composables/useNotifications'

// eslint-disable-next-line object-curly-newline
import { ref, computed, watch, onMounted } from '@vue/composition-api'
import store from '@/store'
import i18n from '@/libs/i18n'
import esLocale from '@fullcalendar/core/locales/es'
import useCommon from '@/views/organization/useCommon'
import useCommonTodo from '@/views/apps/todo/useCommonTodo'
import awsConnection from '@/views/habit/aws';
import axios from '@axios'
import { queryEvent } from '@/@core/queries/calendar'
import { useRouter } from '@core/utils/utils'

export default function userCalendar() {
  // Use toast/notifications
  const toast = useToast()
  const { showSuccessMessage, showErrorMessage } = useNotifications()
  // ------------------------------------------------
  // refCalendar
  // ------------------------------------------------
  const refCalendar = ref(null)

  // ------------------------------------------------
  // calendarApi
  // ------------------------------------------------
  let calendarApi = null

  const isEventHandlerSidebarActive = ref(false)
  const isCalendarOverlaySidebarActive = ref(false)
  const isLoading = ref(true)
  const isInstance = ref(false)
  const isOnline = computed(() => store.state.app.isOnline)

  const { route } = useRouter()
  const routeQueryEventId = route.value.query.originId

  const eventsForStats = ref([]);
  const locationFilter = ref([]);
  const roleFilter = ref([]);
  const workerFilter = ref([]);
  const zoneFilter = ref([]);
  const agencyFilter = ref([]);

  const now = new Date()
  const endDateTime = new Date()
  now.setHours(12, 0, 0, 0)
  endDateTime.setHours(13, 0, 0, 0)
  
  const monthFilter = ref(now.getMonth())
  const yearFilter = ref(now.getFullYear())

  const calendarMonth = ref(null)
  const calendarYear = ref(null)

  const widthBreakpoint = ref(768)

  const getUserData = store.state?.userStore?.userData
  const userId = getUserData.worker_id != null ? getUserData.worker_id.$oid : null
  const username = getUserData.username;
  const commitmentFunctionality = JSON.parse(localStorage.getItem("clientData") || '{}').commitment_functionality

  const { handleError, fetchAndStoreEvents, getMetadataForDropDown, metadataNotMapped, createImprovements, updateCommitmentOfEvent } = useCommon()
  const { sendEmailInstance, sendEmailImprovements, addOriginIdEvent } = useCommonTodo()
  const { singleUpload, getMessagesFromQueue } = awsConnection()

  onMounted(() => {
    calendarApi = refCalendar.value.getApi()
    getMetadataForDropDown({ category: "improvement", option: "notMapped" })

    // Open Calendar Event Handler if there is an event id in the route query
    if (routeQueryEventId) {
      axios
        .post('/graphql', {
          query: queryEvent,
          variables: { query: { _id: routeQueryEventId } }
        })
        .then((response) => {
          if (response.data.errors) throw new Error(response.data.errors[0].message)
          const e = response?.data?.data?.event || {}
          if (e.improvements?.length) {
            e.improvements.forEach(i => {
              if (i.subscribers?.length) i.subscribers = i.subscribers.map(s => s._id)
            })
          }
          event.value = {
            id: e._id,
            title: setEventTitle(e),
            start: e.start instanceof Date ? e.start : new Date(e.start),
            end: e.end instanceof Date ? e.end : new Date(e.end),
            allDay: e.allDay,
            extendedProps: {
              calendar: e.extendedProps.calendar,
              process: e.process,
              attendee: e.attendee,
              confirmation: e.confirmation,
              isInstance: e.isInstance,
              isBehaviourEvaluation: e.isBehaviourEvaluation,
              commitment: e.commitment,
              metadata: e.metadata,
              improvements: e.improvements,
              participants: e.participants.map(e => e._id),
              assistance: e.assistance,
            },
          }
          isEventHandlerSidebarActive.value = true
        })
        .catch((err) => {
          console.log(err)
          showErrorMessage(i18n.t('message.err_calendar_events_list'))
        })
    }

    // Fetch instances received by email
    if (commitmentFunctionality) getMessagesFromQueue()
  })

  // ------------------------------------------------
  // calendars
  // ------------------------------------------------
  const calendarsColor = {
    Pendientes: 'bg-light-warning',
    Vencidas: 'bg-light-danger',
    Realizadas: 'bg-light-success',
    Futuras: 'bg-light-info',
    Propias: 'bg-light-secondary',
    enEspera: 'bg-secondary text-white',
  }

  // ------------------------------------------------
  // event
  // ------------------------------------------------
  const blankEvent = {
    title: i18n.t('metadata.instance'),
    start: now,
    end: endDateTime,
    allDay: false,
    url: '',
    extendedProps: {
      calendar: '',
      guests: [],
      location: '',
      description: '',
      process: '',
      attendee: '',
      link: '',
      confirmation: '',
      isInstance: false,
      isBehaviourEvaluation: false,
      commitment: '',
      metadata: [],
      improvements: [],
      participants: [],
      assistance: '',
    },
  }
  const event = ref(JSON.parse(JSON.stringify(blankEvent)))
  const clearEventData = () => {
    event.value = JSON.parse(JSON.stringify(blankEvent))
  }

  watch(metadataNotMapped, val => {
    if (val && val.length) {
      const filteredMetadata = []
      val.forEach(e => {
        // Event metadata only has instance and instance_leader
        if (e.name === "instance" || e.name === "instance_leader") {
          // Set default instance leader
          if (e.name === "instance_leader") e.answer = username
          filteredMetadata.push(e)
        }
      })
      blankEvent.extendedProps.metadata = filteredMetadata
      if (!event.value.id) clearEventData()
    }
  })

  // *===========================================================================---*
  // *--------- Calendar API Function/Utils --------------------------------------------*
  // Template Future Update: We might move this utils function in its own file
  // *===========================================================================---*

  // ------------------------------------------------
  // (UI) addEventInCalendar
  // ? This is useless because this just add event in calendar and not in our data
  // * If we try to call it on new event then callback & try to toggle from calendar we get two events => One from UI and one from data
  // ------------------------------------------------
  // const addEventInCalendar = eventData => {
  //   toast({
  //     component: ToastificationContent,
  //     position: 'bottom-right',
  //     props: {
  //       title: 'Event Added',
  //       icon: 'CheckIcon',
  //       variant: 'primary',
  //     },
  //   })
  //   calendarApi.addEvent(eventData)
  // }

  // ------------------------------------------------
  // (UI) updateEventInCalendar
  // ------------------------------------------------
  const updateEventInCalendar = (updatedEventData, propsToUpdate, extendedPropsToUpdate) => {
    toast({
      component: ToastificationContent,
      props: {
        title: i18n.t('message.Event_updated'),
        icon: 'CheckIcon',
        variant: 'primary',
      },
    })

    const existingEvent = calendarApi.getEventById(updatedEventData.id)
    

    // --- Set event properties except date related ----- //
    // ? Docs: https://fullcalendar.io/docs/Event-setProp
    // dateRelatedProps => ['start', 'end', 'allDay']
    // eslint-disable-next-line no-plusplus
    for (let index = 0; index < propsToUpdate.length; index++) {
      const propName = propsToUpdate[index]
      existingEvent.setProp(propName, updatedEventData[propName])
    }

    // --- Set date related props ----- //
    // ? Docs: https://fullcalendar.io/docs/Event-setDates
    existingEvent.setDates(updatedEventData.start, updatedEventData.end, { allDay: updatedEventData.allDay })

    // --- Set event's extendedProps ----- //
    // ? Docs: https://fullcalendar.io/docs/Event-setExtendedProp
    // eslint-disable-next-line no-plusplus
    for (let index = 0; index < extendedPropsToUpdate.length; index++) {
      const propName = extendedPropsToUpdate[index]
      existingEvent.setExtendedProp(propName, updatedEventData.extendedProps[propName])
    }

    // Custom code to update process and title
    existingEvent.setExtendedProp('process', updatedEventData.process)
    existingEvent.setProp('title', setEventTitle(updatedEventData))
  }

  const setEventTitle = (eventData) => {
    if (eventData.isInstance) return eventData.title
    const attendee = eventData.attendee?.name || ''
    const process = eventData.process?.name ? eventData.process.name : i18n.t('Confirmation')

    return eventData.isBehaviourEvaluation
      ? `${attendee} - ${eventData.title}`
      : attendee
        ? `${attendee} - ${process}`
        : process
  }

  // ------------------------------------------------
  // (UI) removeEventInCalendar
  // ------------------------------------------------
  const removeEventInCalendar = eventId => {
    toast({
      component: ToastificationContent,
      props: {
        title: i18n.t('message.Event_removed'),
        icon: 'TrashIcon',
        variant: 'primary',
      },
    })
    calendarApi.getEventById(eventId).remove()
    isEventHandlerSidebarActive.value = false
  }

  // ------------------------------------------------
  // grabEventDataFromEventApi
  // ? It will return just event data from fullCalendar's EventApi which is not required for event mutations and other tasks
  // ! You need to update below function as per your extendedProps
  // ------------------------------------------------
  const grabEventDataFromEventApi = eventApi => {
    const {
      id,
      title,
      start,
      end,
      // eslint-disable-next-line object-curly-newline
      extendedProps: { calendar, guests, location, description, process, attendee, link, confirmation, isInstance, isBehaviourEvaluation, commitment, metadata, improvements, participants, assistance },
      allDay,
    } = eventApi

    return {
      id,
      title,
      start,
      end,
      extendedProps: {
        calendar,
        guests,
        location,
        description,
        process,
        attendee,
        link,
        confirmation,
        isInstance,
        isBehaviourEvaluation,
        commitment,
        metadata,
        improvements,
        participants,
        assistance
      },
      allDay,
    }
  }

  // ------------------------------------------------
  // addEvent
  // ------------------------------------------------
  const addEvent = eventData => {
    store.dispatch('calendar/addEvent', { event: eventData, createImprovements })
      .then((eventId) => {
        showSuccessMessage(i18n.t('message.Event_created'))

        // Refetch events
        refetchEvents()

        // Send email to participants with the details of the instance created
        if (eventData.extendedProps.participants?.length && eventId) {
          sendEmailInstance(eventData, eventId, 'add')
        }
        // Get event to obtain the improvements ids and update each one to add origin and origin_id from event
        if (eventId) addOriginIdEvent(eventId)
      })
      .catch((error) => {
        console.log(error)
        showErrorMessage(i18n.t('message.event_create_error'))
      })
  }

  // ------------------------------------------------
  // updateEvent
  // ------------------------------------------------
  const updateEvent = (eventData, improvementsToUpdate = [], improvementsToDelete = []) => {
    // Copy improvements (if any) in another variable to use them later
    const allImprovements = eventData.extendedProps.improvements
      ? [...eventData.extendedProps.improvements, ...improvementsToDelete]
      : [...improvementsToDelete]
    
    store.dispatch('calendar/updateEvent', { event: eventData, createImprovements, commitmentFunctionality }).then(response => {
      const updatedEvent = response.data.data.updateOneEvent
      // eslint-disable-next-line no-underscore-dangle
      updatedEvent.id = updatedEvent._id

      const propsToUpdate = ['id']
      const extendedPropsToUpdate = ['calendar']

      updateEventInCalendar(updatedEvent, propsToUpdate, extendedPropsToUpdate)
      refetchEvents()

      // Send email to assignee and subscribers with the details of the improvements created and updated
      if (allImprovements.length) {
        for (let i = 0; i < allImprovements.length; i++) {
          // If _id is null (new), assign the corresponding value of newImprovementIds
          if (allImprovements[i]._id === null && response.data.data.updateOneEvent.newImprovementIds.length > 0) {
            allImprovements[i]._id = response.data.data.updateOneEvent.newImprovementIds.shift()
            allImprovements[i].isNew = true
          }
        }
        sendEmailImprovements(allImprovements, null, improvementsToUpdate, improvementsToDelete)
      }

      // Send email to participants with the details of the instance updated
      if (eventData.extendedProps.participants?.length && updatedEvent.id) {
        sendEmailInstance(eventData, updatedEvent.id, 'update')
      }

      // Update commitment asociated with event
      if (eventData.extendedProps.commitment?._id) {
        updateCommitmentOfEvent(eventData.extendedProps.commitment._id, eventData.start)
      }
    })
  }

  // ------------------------------------------------
  // removeEvent
  // ------------------------------------------------
  const removeEvent = ({ eventData, deletedJustification, improvementsToDelete }) => {
    store.dispatch('calendar/removeEvent', { eventData, deletedJustification, deletedBy: userId, improvementsToDelete }).then(() => {
      removeEventInCalendar(eventData.id)
    })
    if (eventData.extendedProps.participants?.length && eventData.id) {
      sendEmailInstance(eventData, eventData.id, 'delete')
    }
  }

  // ------------------------------------------------
  // refetchEvents
  // ------------------------------------------------
  const refetchEvents = (data) => {
    calendarApi.refetchEvents()
  }

  // ------------------------------------------------
  // selectedCalendars
  // ------------------------------------------------
  const selectedCalendars = computed(() => store.state.calendar.selectedCalendars)

  watch(selectedCalendars, () => {
    refetchEvents()
  })

  // ------------------------------------------------
  // instancesFromEmail
  // ------------------------------------------------
  const instancesFromEmail = computed(() => store.state.calendar.instancesFromEmail)

  watch(instancesFromEmail, () => {
    refetchEvents()
  })

  // ------------------------------------------------
  // translate Calendar
  // ------------------------------------------------
  const language = computed(() => i18n.locale)

  watch(language, () => {
    switch (language.value) {
      case "en":
        calendarApi.setOption('locale')
        break;
      case "es":
        calendarApi.setOption('locale', esLocale)
        break;
      default:
        break;
    }
  })

  // ------------------------------------------------
  // update Filters
  // ------------------------------------------------
  const updateFilters = (filters) => {
    locationFilter.value = filters.locationFilter;
    roleFilter.value = filters.roleFilter;
    workerFilter.value = filters.workerFilter;
    monthFilter.value = filters.monthFilter;
    yearFilter.value = filters.yearFilter;
    zoneFilter.value = filters.zoneFilter;
    agencyFilter.value = filters.agencyFilter;
    if (monthFilter.value >= 0 && yearFilter.value && (monthFilter.value !== calendarMonth.value || yearFilter.value !== calendarYear.value)) {
      calendarApi.gotoDate(new Date(yearFilter.value, monthFilter.value, 1))
    }
    else refetchEvents();
  }

  // ------------------------------------------------
  // Instance event handler
  // ------------------------------------------------
  const openInstanceEventHandler = () => {
    isInstance.value = true
    isEventHandlerSidebarActive.value = true
  }

  // ------------------------------------------------
  // Get event border color based on improvements status
  // ------------------------------------------------
  const getBorderColor = (improvements) => {
    if (!improvements?.length) return null

    let hasOverdueImprovements = false
    let hasPendingImprovements = false

    improvements.forEach(i => {
      const dueDateFormatted = i.dueDate ? new Date(`${i.dueDate.slice(0, 10)} 12:00:00`) : null
      if (!i.completed) {
        if (dueDateFormatted < now) hasOverdueImprovements = true
        else hasPendingImprovements = true
      }
    })

    return `border-${hasOverdueImprovements ? 'danger' : hasPendingImprovements ? 'warning' : 'success'}`
  }

  // --------------------------------------------------------------------------------------------------
  // AXIOS: fetchEvents
  // * This will be called by fullCalendar to fetch events. Also this can be used to refetch events.
  // --------------------------------------------------------------------------------------------------
  const fetchEvents = (info, successCallback) => {
    // If there's no info => Don't make useless API call
    if (!info) return

    isLoading.value = true

    let query = {
      calendars: selectedCalendars.value,
      locations: locationFilter.value,
      roles: roleFilter.value,
      workers: workerFilter.value,
      month: monthFilter.value,
      year: yearFilter.value,
      zones: zoneFilter.value,
      agencies: agencyFilter.value,
      commitmentFunctionality
    }
    
    // Fetch Events from API endpoint
    store
      .dispatch('calendar/fetchEvents', query)
      .then(response => {
        const events = response.map(value => {
          const s = { ...value }
          // eslint-disable-next-line no-prototype-builtins
          if (s.hasOwnProperty('_id')) {
            // eslint-disable-next-line no-underscore-dangle
            s.id = s?._id
            // eslint-disable-next-line no-underscore-dangle
            delete s._id
          }

          // Event Title
          s.title = setEventTitle(s)

          // eslint-disable-next-line no-underscore-dangle
          if (s.confirmation && !s.confirmation.pending) {
            // Add 'url' property to redirect automatically when event is clicked
            // s.url = `/habit/confirmation/view/${s.confirmation._id}`

            if (!s.start) {
              s.start = new Date(parseInt(s.confirmation._id.substring(0, 8), 16) * 1000)
              s.end = new Date(parseInt(s.confirmation._id.substring(0, 8), 16) * 1000 + 3600)
            }
          }

          if (s.participants?.length) {
            s.participants = s.participants.map(e => e._id)
          }

          if (s.improvements?.length) {
            s.improvements.forEach(e => {
              if (e.subscribers?.length) e.subscribers = e.subscribers.map(s => s._id)
            })
          }
          
          return s
        })
        calendarMonth.value = calendarApi?.getDate().getMonth()
        calendarYear.value = calendarApi?.getDate().getFullYear()
        eventsForStats.value = events;
        successCallback(events)
        if (isOnline.value) fetchAndStoreEvents()
      })
      .catch(error => {
        console.log(error)
        if (error?.isStorageError) handleError({ isStorageError: true })
        else handleError({ error, defaultMessage: i18n.t('message.err_calendar_events_list') })
      })
      .finally(() => {
        isLoading.value = false
      })
  }

  // ------------------------------------------------------------------------
  // calendarOptions
  // * This isn't considered in UI because this is the core of calendar app
  // ------------------------------------------------------------------------
  const calendarOptions = ref({
    locale: i18n.locale === "es" ? esLocale : null,
    plugins: [dayGridPlugin, interactionPlugin, timeGridPlugin, listPlugin],
    initialView: window.innerWidth < widthBreakpoint.value ? 'listMonth' : commitmentFunctionality ? 'timeGridWeek' : 'dayGridMonth',
    headerToolbar: {
      start: 'sidebarToggle, prev,next, title',
      end: 'dayGridMonth,timeGridWeek,timeGridDay,listMonth',
    },
    events: fetchEvents,

    // Responsive calendar view
    windowResize: function() {
      calendarApi.changeView(window.innerWidth < widthBreakpoint.value ? 'listMonth' : commitmentFunctionality ? 'timeGridWeek' : 'dayGridMonth')
    },

    /*
      Enable dragging and resizing event
      ? Docs: https://fullcalendar.io/docs/editable
    */
    editable: true,

    /*
      Enable resizing event from start
      ? Docs: https://fullcalendar.io/docs/eventResizableFromStart
    */
    eventResizableFromStart: true,

    /*
      Automatically scroll the scroll-containers during event drag-and-drop and date selecting
      ? Docs: https://fullcalendar.io/docs/dragScroll
    */
    dragScroll: true,

    /*
      Max number of events within a given day
      ? Docs: https://fullcalendar.io/docs/dayMaxEvents
    */
    dayMaxEvents: 4,

    /*
      Determines if day names and week names are clickable
      ? Docs: https://fullcalendar.io/docs/navLinks
    */
    navLinks: true,

    eventClassNames({ event: calendarEvent }) {
      const extProps = calendarEvent._def.extendedProps
      const bgColor = extProps.isInstance || extProps.attendee?._id === userId
        ? calendarsColor["Propias"]
        : extProps.confirmation?.pending
          ? calendarsColor["enEspera"]
          : calendarsColor[extProps.calendar]

      // Set border color if event has improvements
      const borderColor = commitmentFunctionality ? getBorderColor(extProps.improvements) : null

      // Background Color + Border Color
      return [ bgColor, borderColor ]
    },
    eventClick({ event: clickedEvent, jsEvent }) {
      // * Only grab required field otherwise it goes in infinity loop
      // ! Always grab all fields rendered by form (even if it get `undefined`) otherwise due to Vue3/Composition API you might get: "object is not extensible"
      event.value = grabEventDataFromEventApi(clickedEvent)
      if (!isOnline.value) jsEvent.preventDefault()

      // eslint-disable-next-line no-use-before-define
      isEventHandlerSidebarActive.value = true
    },

    customButtons: {
      sidebarToggle: {
        // --- This dummy text actual icon rendering is handled using SCSS ----- //
        text: 'sidebar',
        click() {
          // eslint-disable-next-line no-use-before-define
          isCalendarOverlaySidebarActive.value = !isCalendarOverlaySidebarActive.value
        },
      },
    },

    dateClick(info) {
      /*
        ! Vue3 Change
        Using Vue.set isn't working for now so we will try to check reactivity in Vue 3 as it can handle this automatically
        ```
        event.value.start = info.date
        ```
      */
      event.value = JSON.parse(JSON.stringify(Object.assign(event.value, { start: info.date })))
      // eslint-disable-next-line no-use-before-define
      isEventHandlerSidebarActive.value = false
    },

    /*
      Handle event drop (Also include dragged event)
      ? Docs: https://fullcalendar.io/docs/eventDrop
      ? We can use `eventDragStop` but it doesn't return updated event so we have to use `eventDrop` which returns updated event
    */
    eventDrop({ event: droppedEvent }) {
      updateEvent(grabEventDataFromEventApi(droppedEvent))
    },

    /*
      Handle event resize
      ? Docs: https://fullcalendar.io/docs/eventResize
    */
    eventResize({ event: resizedEvent }) {
      updateEvent(grabEventDataFromEventApi(resizedEvent))
    },

    // Get direction from app state (store)
    direction: computed(() => (store.state.appConfig.isRTL ? 'rtl' : 'ltr')),
    rerenderDelay: 350,

    // Add aditional information to display for each event in calendar
    eventContent({ event }) {
      const totalImprovements = event.extendedProps.improvements?.length
      const isBehaviourEvaluation = event.extendedProps.isBehaviourEvaluation

      let completedImprovements = 0
      event.extendedProps.improvements.forEach(e => {
        if (e.completed) completedImprovements++
      })

      const title = event.extendedProps.isInstance && event.title === 'Seguimiento'
        ? `${i18n.t('message.follow_up')} IA` // 'Seguimiento IA'
        : event.extendedProps.isInstance
          ? (event.extendedProps.metadata?.find(e => e.name === "instance")?.answer || i18n.t('metadata.instance'))
          : event.title // 'Instancia'

      return {
        // The time that was located before the title is extracted
        // ${event.start.getHours()}h --> commitmentFunctionality
        // ${event.start.getHours()}h --> !commitmentFunctionality
        html:
        // For Arauco (commitmentFunctionality), the behavior commitments (isBehaviourEvaluation) are shown on the calendar, otherwise they are not shown.
        `
          <div style='overflow: hidden; text-align: left; display: ${commitmentFunctionality ? 'block' : 'none'} '>
            ${isBehaviourEvaluation
                ? '<span style="font-size: 1rem;">&#9655;</span>'
                : '<span style="font-size: 1.1rem;">&#x1F5D3;</span>'
              }${title}
            <br>
            ${isBehaviourEvaluation ? '<br>'
              : totalImprovements 
                ? `${totalImprovements
                  ? '<span style="font-size: 1rem; padding-left: 0.2rem">&#x2713;</span>'
                  : ''} ${completedImprovements} ${i18n.t('message.of')} ${totalImprovements} comp.`
                : i18n.t('message.no_commitments_short')}
          </div>

          <div style='overflow: hidden; text-align: left; display: ${commitmentFunctionality ? 'none' : 'block'} '>           
            <span style="">             
            ${title}
            </span>
          </div>        
        `
      }
    }
  })

  // ------------------------------------------------------------------------

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    refCalendar,
    isCalendarOverlaySidebarActive,
    calendarOptions,
    event,
    clearEventData,
    addEvent,
    updateEvent,
    removeEvent,
    refetchEvents,
    fetchEvents,
    updateFilters,
    eventsForStats,
    calendarMonth,
    calendarYear,
    isLoading,
    isInstance,
    openInstanceEventHandler,
    commitmentFunctionality,

    // ----- UI ----- //
    isEventHandlerSidebarActive,
  }
}
